/*Setting background colour for page*/
body {
  background: #e1e1df
}

/*The div that other components should be stored in.*/
.window {
  margin: auto;
  max-width: 1440px;
  min-width: 360px;
  padding-top: 20px
}

/*The wrapper/container for each table*/
.pane {
  padding: 20px 10px;
}

/*The wrapper/container for a component (e.g. ComponentStatusDisplay, IssuePost, NoticePost)*/
.component {
  background: #ffffff !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  /*border: solid 1px #e1e1df;*/
  border-radius: 10px
}

/*The icon shown on a status display*/
.icon {
  font-size: 35px !important
}

/*The icon shown in the legend*/
.legendIcon {
  font-size: 15px !important;
  margin-bottom: -2px;
}

/*The Communicate logo shown at the top of the page*/
.logo {
  width: 250px;
  display: block;
  margin: 0 auto;
}

/*Properties for style classes when the window is at least 768px (tablet size)*/
@media (min-width: 768px) {
  .pane {
    padding: 20px 100px
  }

  .icon {
    font-size: 45px !important
  }

  .logo {
    width: 450px;
    padding-bottom: 20px
  }
}

/*The grid that contains the content in a table*/
.tableContent {
  padding-top: 10px;
  padding-bottom: 30px;
}

/*Colour for icon that represents "UP" status*/
.upIcon {
  color: #007400
}

/*Colour for icon that represents "WARN" status*/
.warnIcon {
  color: #e88b00
}

/*Colour for icon that represents "DOWN" status*/
.downIcon {
  color: #be0000
}

/*Colour for icon that represents "UNKNOWN" status*/
.unknownIcon {
  color: #9e9f9b
}