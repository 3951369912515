/*Table headers*/
.MuiTypography-h4 {
    font-size: 20px !important;
    font-weight: bold !important
}

/*Status display titles (component names)*/
.MuiTypography-h5 {
    font-size: 16px !important;
    font-weight: bold !important
}

/*Post titles (for issues and notices*/
.MuiTypography-h6 {
    font-size: 12px !important;
    font-weight: bold !important
}

/*Post subtitles*/
.MuiTypography-subtitle1 {
    font-size: 12px !important;
    font-weight: bold !important;
    color: #636360
}

/*Post body (content/description for notices and issues)*/
.MuiTypography-body1 {
    font-size: 12px !important;
    color: #636360
}

/*Smaller font sizes for when width is less than or equal to 768px (usual width for a tablet)*/
@media (min-width: 768px) {
    .MuiTypography-h4 {
        font-size: 24px !important
    }

    .MuiTypography-h5 {
        font-size: 18px !important
    }

    .MuiTypography-h6 {
        font-size: 14px !important
    }

    .MuiTypography-subtitle1 {
        font-size: 14px !important
    }

    .MuiTypography-body1 {
        font-size: 14px !important
    }
}
